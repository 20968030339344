@use "src/styles/general/mixins" as *;

.link {
  &--view-all {
    @include body-large();
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}
