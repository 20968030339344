@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;

.block.block--type-cta {
  padding: 16px 24px;
  background-color: $color-navy;
  color: $color-white;
  border-radius: 16px;

  .block__title {
    color: $color-white;
  }

  .block__content {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }

  .block__inner {
    display: flex;
    flex-flow: column;
    row-gap: 8px;
  }

  .block__cta {
    button,
    .button {
      width: 100%;
    }
  }

  @include md() {
    .block__content {
      flex-flow: row nowrap;
      align-items: center;
    }

    .block__cta {
      margin-left: auto;

      button,
      .button {
        width: auto;
      }
    }
  }
}
