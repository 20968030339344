@use "src/styles/general/breakpoints" as *;

.homepage-course-block {
  .block__content {
    display: flex;
    flex-flow: column;
    gap: 24px;

    @include md() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }

  &__widget {
    @extend .box;
    padding: 24px;
  }
}
