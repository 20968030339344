@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.empty-list {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 16px;
  padding: 48px 10px;
  border-radius: 16px;
  background: $color-light-grey-2;
  color: $color-navy;
  text-align: center;

  &__icon {
    padding: 8px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
  }

  &__content {
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    max-width: 287px;
  }

  &__title {
    @include body-large-bold();
  }

  &__description {
    @include body();
  }

  @include md() {
    flex-flow: row nowrap;
    column-gap: 24px;
    justify-content: center;
    padding: 80px 10px;
    text-align: start;

    &__content {
      max-width: 412px;
    }
  }
}
