@use "src/styles/general/mixins" as *;

.module-nav {
  min-height: 100%;

  &__title {
    > button {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: -8px;
    }
  }

  &__progress {
    .region--sidebar-left &,
    .region--sidebar-right & {
      height: 8px;
    }

    .homepage-course-block & {
      display: none;
    }
  }

  &__items {
    display: flex;
    flex-flow: column;
    row-gap: 16px;
  }

  &__item {
    @include body();

    .region--sidebar-left &,
    .region--sidebar-right & {
      @include body-small();
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    align-self: start;
    overflow: hidden;

    svg,
    img {
      max-width: 100%;
      max-height: 100%;
    }

    .region--sidebar-left &,
    .region--sidebar-right & {
      width: 16px;
      height: 16px;
      flex: 0 0 16px;

      .chakra-spinner {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__subtitle {
    .region--sidebar-left &,
    .region--sidebar-right & {
      display: none;
    }
  }
}
