@use "src/styles/general/colors" as *;

.wipe {
  opacity: 0;
  transition: opacity .5s ease;
  position: fixed;
  z-index: 9999;

  .animation-player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background: $color-light-grey;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }

    .lottie-player {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      aspect-ratio: initial !important;

      canvas {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &--animation-fadeout {
    pointer-events: none;
  }
}
