@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.label {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
  @include buttons();

  &--locked {

  }
}