@use "src/styles/general/mixins" as *;

.parse-html {
  @include body();

  h1, h2, h3, h4, h5, h6 {
    @include heading();
  }

  h1 {
    @include h1();
  }

  h2 {
    @include h2();
  }

  h3 {
    @include h3();
  }

  h4 {
    @include h4();
  }

  h5 {
    @include h5();
  }

  h6 {
    @include h6();
  }
}
