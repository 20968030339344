@keyframes icon-click {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loading-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@mixin animation-icon-click() {
  animation: icon-click 1s ease;
}

@mixin animation-loading-pulse() {
  animation: loading-pulse 2s ease-in-out infinite;
}
