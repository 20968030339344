@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.resources {
  &__list {
    display: flex;
    flex-flow: column;
    gap: 16px;

    @include md() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.resource {
  &--locked {
    opacity: 0.5;
  }

  &--card {
    @extend .box;
    padding: 20px;
    display: flex;
    flex-flow: column;
    row-gap: 24px;
    min-height: 168px;

    @include md() {
      padding: 24px;
    }

    .resource__content {
      display: flex;
      flex-flow: column;
      row-gap: 8px;

      p {
        @include body();
      }
    }

    .resource__footer {
      display: flex;
      flex-flow: row wrap;
      margin-top: auto;

      .button--help {
        margin-left: auto;
      }
    }

    .resource__title {
      @include h5();
    }
  }
}
