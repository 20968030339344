@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;
@use "src/styles/general/animation" as *;

.module--card {
  @extend .box;
  display: flex;
  flex-flow: column;
  row-gap: 24px;
  padding: 24px;
  min-height: 208px;

  .module__info {
    display: flex;
    flex-flow: column;
    row-gap: 8px;
  }

  .module__title {
    @include h5();
    display: flex;
    flex-flow: row nowrap;

    .module__status {
      margin-left: auto;
      @include body-large-bold();
      color: $color-orange;
    }
  }

  .module__subtitle {
    @include body-large();
  }

  .module__footer {
    display: flex;
    flex-flow: column;
    row-gap: 24px;
    margin-top: auto;
  }

  .module__actions {
    display: flex;
    flex-flow: row wrap;
    gap: 8px 24px;
  }

  &.module--active {
    background-color: $color-washed-orange;
  }

  &.module--locked {
    opacity: 0.5;
  }

  &.module--loading {
    @include animation-loading-pulse();
  }
}
