@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;

@keyframes circle-wipe-in {
  from {
    width: 0;
    height: 0;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  to {
    width: calc(100vw + 100vh);
    height: calc(100vw + 100vh);
    width: 200vmax;
    height: 200vmax;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes circle-wipe-out {
  from {
    width: calc(100vw + 100vh);
    height: calc(100vw + 100vh);
    width: 200vmax;
    height: 200vmax;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  to {
    width: 0;
    height: 0;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

.animation-player {
  position: relative;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &__muted {
    position: absolute;
    right: 16px;
    bottom: 16px;
    padding: 8px;
    border-radius: 50%;
    background-color: rgba(42, 42, 42, 0.50);

    svg {
      width: 32px;
      height: 32px;
    }

    @include lg() {
      right: 24px;
      bottom: 24px;
      padding: 16px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .player-controls {
    @include lg() {
      position: absolute;
      left: 24px;
      bottom: 24px;
    }
  }
}

.animated-container {
  &--circle-wipe-fullscreen {
    .animated-container__scroll {
      height: 100%;
    }

    &.animated-container--open,
    &.animated-container--animation-opening,
    &.animated-container--animation-closing {
      z-index: 9999 !important;
      background: $color-black;

      .animated-container__scroll {
        position: absolute;
        width: 100vw;
        height: 100vh;
        height: 100dvh;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .animated-container__content {
        min-height: 100%;
      }
    }

    &.animated-container--open {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transform: none;
      border-radius: 0;
      background: $color-black;

      .animated-container__scroll {
        overflow-y: auto;
      }
    }

    &.animated-container--animation-opening {
      overflow: hidden;
      animation-name: circle-wipe-in;
      animation-duration: .5s;
      animation-timing-function: cubic-bezier(.48,.48,1,.15);
    }

    &.animated-container--animation-closing {
      overflow: hidden;
      animation-name: circle-wipe-out;
      animation-duration: 1s;
      animation-timing-function: ease;
      width: 0;
      height: 0;
    }

    &.animated-container--animation-fadein {
      animation-name: fade-in;
      animation-duration: .5s;
      animation-timing-function: ease;
    }
  }
}
