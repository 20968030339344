@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.consultant {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  // Full is supposed to be used within modal dialog that has padding as well.
  // @todo Refactor so padding is constant or set on wrapping element. Remove modifier from element if not needed after change.
  &--full {
    padding: 0;
  }

  &__actions {
    margin-top: auto;
    padding-top: 32px;
  }

  &__badges {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__header {
    align-items: center;
    display: flex;
    gap: 16px;
  }

  &__label {
    @include body-large-bold;

    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 4px;
  }

  &__name {
    @include h4;
  }

  &__photo {
    border: 1px solid $color-tangerine;
    border-radius: 50%;
    display: flex;
    flex: 0 0 72px;
    height: 72px;
    overflow: hidden;

    @include lg {
      flex: 0 0 88px;
      height: 88px;
    }

    img {
      // Make sure image takes all space. Though processed image arrives from backend and is square at the moment.
      object-fit: cover;
    }
  }

  &__skills {
    border-left: 1px solid $color-tangerine;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 16px;
  }
}
