@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;
@use "src/styles/general/animation" as *;

.encouragements-panel {
  display: flex;
  flex-flow: column;
  gap: 16px;

  &__header {
    display: flex;
    flex-flow: nowrap;
    row-gap: 8px;

    .link--view-all {
      margin-left: auto;
    }
  }

  &__title {
    @include body-large();
    opacity: 0.5;
  }
}

.encouragement-list {
  display: grid;
  gap: 16px;

  @include md() {
    grid-template-columns: repeat(2, 1fr);
  }

  .activity--full & {
    margin-top: 24px;

    .encouragement--liked {
      .encouragement__inner,
      .encouragement__bottom:before {
        background-color: $color-washed-tangerine;
      }

      .encouragement__bottom:after {
        border-top-color: #e6c8bd;
      }
    }
  }
}

.encouragement {
  display: flex;
  flex-flow: column;
  transition: opacity .6s ease;

  filter:
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.10))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10))
    drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 8px 2px rgba(0, 0, 0, 0.00));

  &--loading {
    @include animation-loading-pulse();

    .encouragement__info,
    .encouragement__actions {
      opacity: 0;
    }
  }

  &--sticky {
    .encouragement__inner,
    .encouragement__bottom:before {
      background-color: $color-washed-tangerine;
    }

    .encouragement__bottom:after {
      border-top-color: #e6c8bd;
    }
  }

  &__inner {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
    padding: 16px 16px 0;
    border-radius: 8px 8px 0 0;
    background: $color-light-grey-2;
    color: $color-navy;

    @include md() {
      flex-flow: column;
    }
  }

  &__info,
  &__actions {
    transition: opacity .6s ease;
  }

  &__info {
    display: flex;
    flex-flow: column;
    row-gap: 16px;
    flex: 1;
  }

  &__description {
    @include body-large-bold();
    min-height: 24px;
  }

  &__course {
    @include body();
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    margin-top: auto;
  }

  &__module,
  &__segment {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 8px;
    align-items: center;
  }

  &__actions {
    display: flex;
    flex-flow: column;
    gap: 16px;

    @include md() {
      flex-flow: row;
    }
  }

  &__bottom {
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-flow: row nowrap;
    height: 17px;
    margin-top: -1px;

    &:before,
    &:after {
      content: '';
      display: block;
    }

    &:before {
      flex: 1;
      border-radius: 0 0 0 8px;
      background: $color-light-grey-2;
    }

    &:after {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 16px 24px 0 0;
      border-color: #E2E2E2 transparent transparent transparent;
    }
  }

  .parse-html {
    .highlighted {
      color: $color-red;
    }
  }
}
