@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.saved-content {
  color: $color-navy;
  &__header {
    display: flex;
    margin-bottom: 16px;
  }

  &__title {
    @include h5();
  }

  &__slide {
    width: auto;
    height: auto;
    margin-right: 8px;

    @include lg() {
      margin-right: 16px;
    }

    .segment--vertical {
      height: 100%;
    }
  }
}