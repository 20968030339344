@use "src/styles/general/animation" as *;

.pin {
  &--processing {
    .pin__button {
      cursor: initial;

      svg {
        @include animation-loading-pulse();
      }
    }
  }

  &--clicked {
    .pin__button {
      svg {
        @include animation-icon-click();
      }
    }
  }
}
