@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.player-controls {
  position: relative;
  display: flex;
  flex-flow: column;
  row-gap: 4px;
  max-width: 100%;
  color: $color-white;
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  background: rgba(42, 42, 42, 0.5);
  border-radius: 8px;
  transition: opacity cubic-bezier(.4, 0, .6, 1) .6s;

  @include lg() {
    width: 416px;
  }

  &__settings {
    display: flex;
    flex-flow: column;
    gap: 8px;
    position: absolute;
    right: 0;
    bottom: 100%;
    transform: translateY(-8px);
    padding: 8px;
    background: rgba(42, 42, 42, 0.95);
    border: 1px solid rgba(255, 222, 209, 0.5);
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.01), 0px 3px 2px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.09), 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 99;

    &--hidden {
      display: none;
    }
  }

  &__setting {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
    min-width: 200px;
  }

  &__setting-label {
    @include body();
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
    flex: 1;
    user-select: none;
    cursor: pointer;

    > span {
      flex: 1;
    }
  }

  &__buttons {
    display: flex;
    flex-flow: row wrap;
    gap: 4px 8px;
  }

  &__progress {
    display: flex;
    align-items: center;
    column-gap: 8px;

    input[type="range"] {
      margin: 4px 0;
      width: 100%;
      -webkit-appearance: none;
      height: 4px;
      border-radius: 4px;
      background: $color-light-grey-3;
      cursor: pointer;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $color-orange;
      }

      ::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }
  }

  &__time-duration {
    span.time {
      min-width: 10px;
    }
  }

  &__volume {
    position: relative;
    height: 24px;
    display: flex;

    .range-container--vertical {
      display: flex;
      align-items: center;
      position: absolute;
      width: 80px;
      height: 24px;
      left: 50%;
      bottom: 100%;
      transform: rotate(-90deg) translate(-8px, 0);
      transform-origin: left center;
      z-index: 99;
    }

    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: #E2E2E2;
      box-shadow: 0 0 2px rgba(0,0,0,0.2);
      border: 1px solid rgba(0,0,0,0.1);
      transition: opacity cubic-bezier(.4, 0, .6, 1) .2s;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #FFFFFF;
        box-shadow: 0 0 4px rgba(0,0,0,0.2);
        border: 1px solid rgba(0,0,0,0.1);
      }

      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }
  }

  &__separator {
    flex: 1;
  }
}
