@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.chakra-popover__popper {
  .chakra-popover__content {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 8px;
    padding: 12px;
    width: auto;
    max-width: 176px;
    background: $color-washed-tangerine;
    border: 1px solid $color-washed-blue;
    border-radius: 0 8px 8px;
    color: $color-navy;
    @include body();
    font-weight: 400;
    outline: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.09), 0px 3px 2px 0px rgba(0, 0, 0, 0.05), 0px 5px 2px 0px rgba(0, 0, 0, 0.01), 0px 8px 2px 0px rgba(0, 0, 0, 0.00);

    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    @include md() {
      max-width: 320px;
    }
  }

  .chakra-popover__header,
  .chakra-popover__body {
    padding: 0;
    border: none;
    background: none;

    .parse-html {
      font-size: inherit;
    }
  }

  .chakra-popover__header {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .chakra-popover__close-btn {
    position: initial;
    margin-left: auto;
    order: 1;
  }

  &[data-popper-placement="left"],
  &[data-popper-placement="left-start"],
  &[data-popper-placement="left-end"],
  &[data-popper-placement="bottom"],
  &[data-popper-placement="bottom-end"] {
    .chakra-popover__content {
      border-radius: 8px 0 8px 8px;
    }
  }
}
