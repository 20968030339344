@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/colors" as *;
@use "src/styles/general/mixins" as *;

.expandable-panel {
  position: fixed;
  bottom: 60px;
  right: -1px;
  max-width: 484px;
  width: 100%;
  z-index: 10;
  border-radius: 16px 16px 0 0;
  border: 1px solid $color-washed-blue;
  background-color: $color-white;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.09), 0px 3px 2px 0px rgba(0, 0, 0, 0.05), 0px 5px 2px 0px rgba(0, 0, 0, 0.01), 0px 8px 2px 0px rgba(0, 0, 0, 0.00);

  &.expandable-panel--open {
    .expandable-panel__toggle {
      transform: rotate(-180deg);
    }
  }

  .expandable-panel__header {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    align-items: center;
    padding: 8px;
    background-color: $color-washed-blue;
    border-radius: 16px 16px 0 0;
  }

  .expandable-panel__toggle {
    transition: all .4s ease;

    &:hover {
      background-color: transparent;
    }
  }

  .expandable-panel__tabs {
    flex: 1;
  }

  .expandable-panel__tab {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 12px;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    @include body-bold();

    &[aria-selected=true] {
      background-color: rgba(0, 0, 0, 0.05);
    }

    @include lg() {
      padding: 16px 24px;
    }
  }

  .expandable-panel__panels {
    max-height: 296px;
    overflow-y: auto;
  }

  @include lg() {
    bottom: 0;
    right: 16px;
  }
}
