@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.segment {
  &__progress {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    gap: 8px;
  }

  &__progressbar {
    width: 176px;
    max-width: 100%;

    @include md() {
      width: 244px;
    }
  }

  &--locked {
    opacity: 0.5;
  }
}

.segment--card {
  display: flex;
  flex-flow: row nowrap;
  min-height: 144px;
  position: relative;
  color: $color-navy;

  .segment__thumbnail {
    display: none;

    @include md() {
      display: initial;
      flex: 0 0 198px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 0 0 16px;
      }
    }
  }

  .segment__content {
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding: 24px;

    @include md() {
      padding: 16px 24px;
      align-self: center;
    }
  }

  .segment__footer {
    display: flex;
    flex-flow: row wrap;
    gap: 16px 24px;
  }

  .segment__actions {
    display: none;
    @include md() {
      display: flex;
      flex-flow: row wrap;
      gap: 8px 24px;
    }
  }

  .segment__body {
    display: flex;
    flex-flow: column;
    row-gap: 4px;

    @include md() {
      row-gap: 8px;
    }
  }

  .segment__title {
    padding-right: 32px;
    @include h6();

    @include md() {
      padding-right: 0;
    }
  }

  .segment__module {
    @include tags();
    color: $color-darkblue;
    text-transform: uppercase;
    order: -1;

    .course-content & {
      display: none;
    }
  }

  .segment__progress {
    @include body-small();
  }

  .like {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;

    @include md() {
      position: static;
      top: auto;
      right: auto;
    }
  }

  &.segment--active {
    background-color: $color-washed-tangerine;

    .segment__progress {
      column-gap: 16px;
    }
  }

  &.segment--vertical {
    flex-flow: column;
    width: 164px;
    min-height: 220px;

    .segment__thumbnail {
      position: relative;
      height: 0;
      padding-top: 56.25%;
      display: initial;
      flex: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 16px 0 0;
      }
    }

    .segment__content {
      flex: 1;
      align-self: stretch;
      padding: 16px;
    }

    .segment__body {
      flex: 1;

      @include md() {

      }
    }

    .segment__footer {
      position: absolute;
      right: 8px;
      top: 8px;

      @include md() {
        position: initial;
        margin-top: auto;
      }
    }

    .segment__title {
      padding-right: 0;
    }

    .segment__module {
      order: initial;
      margin-top: auto;

      @include md() {
        margin-top: initial;
      }
    }

    .segment__actions,
    .segment__progress {
      display: none;
    }

    .segment__actions {
      @include md() {
        display: initial;
      }
    }

    .like {
      position: initial;

      @include md() {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }

    @include md() {
      width: 198px;
      min-height: 298px;
    }
  }
}
