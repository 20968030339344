@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.course {
  color: $color-navy;
  display: flex;
  flex-flow: column;
  row-gap: 24px;

  @include lg() {
    row-gap: 32px;
  }

  &__title {
    @include h4();
    display: flex;
    flex-flow: row nowrap;
    column-gap: 8px;
    align-items: center;
  }

  &__description {
    @include body();
  }

  &__progress {
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    @include body-small();
  }

  &__content {
    display: flex;
    flex-flow: column;
    gap: 16px;

    @include md() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
