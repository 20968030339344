@use "src/styles/general/colors" as *;
@use "src/styles/general/mixins" as *;

.note {
  display: flex;
  flex-flow: column;
  row-gap: 24px;

  &--card {
    @extend .box;
    padding: 24px;

    .note__body {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      max-height: 240px;
      text-overflow: ellipsis;
      overflow: hidden;

      p + p {
        margin-top: 0;
      }
    }
  }

  &__inner {
    flex: 1;
    display: flex;
    flex-flow: column;
    row-gap: 16px;
  }

  &__content {
    flex: 1;
  }

  &__content,
  &__meta {
    display: flex;
    flex-flow: column;
    row-gap: 8px;
  }

  &__title {
    @include h6();
    display: flex;
    flex-flow: row nowrap;
  }

  &__checkbox {
    margin-left: auto;
  }

  &__body {
    @include body-large();

    p + p {
      margin-top: 12px;
    }
  }

  &__meta {
    @include body();

    .note__meta-row a {
      text-decoration: none;
      color: $color-navy;
      font-weight: inherit;
    }
  }

  &__meta-row {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 8px;
    align-items: center;
  }

  &__actions {
    display: flex;
    flex-flow: row wrap;
    column-gap: 24px;
    row-gap: 16px;
  }

  &--expanded {
    textarea {
      padding: 8px;
    }
  }
}
