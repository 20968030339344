@use "src/styles/general/breakpoints" as *;

.filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 8px;

  @include lg() {
    justify-content: initial;
    column-gap: 32px;
  }
}