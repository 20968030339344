@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;

.swiper {
  padding: 2px 24px;
  margin: 0 -24px;

  .block & {
    padding-bottom: 2px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 100%;
    color: $color-navy;
    z-index: 1;
    cursor: pointer;

    &:before {
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      background: url("../../../img/icons/chevron--right.svg") no-repeat;
    }

    @include lg() {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
  }

  .swiper-button-prev {
    transform: scaleX(-1);
    right: auto;
    left: 0;
  }

  .region--main .block & {
    padding: 2px 20px;
    margin: 0 -20px;

    @include lg() {
      padding: 2px 48px;
      margin: -2px -48px;

      .swiper-button-prev,
      .swiper-button-next {
        width: 46px;
        background: $color-white;
        background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
      }

      .swiper-button-next {
        right: 0;

      }

      .swiper-button-prev {
        left: 0;
      }
    }
  }
}
