.parse-html {
  *+p,
  *+ol,
  *+ul,
  li+li {
    margin-top: 8px;
  }

  .tooltip {
    text-decoration: underline;
    cursor: help;
  }
}
