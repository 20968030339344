@use "src/styles/general/variables" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.layout-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  max-width: $site-max-width;
  margin: 0 auto;

  > .region {
    grid-column: span 12/span 12;
  }

  @include lg() {
    padding: 48px 20px 104px;
    grid-template-rows: auto 1fr;
  }

  @include xl() {
    padding: 48px 48px 104px;
    grid-template-rows: auto;
  }

  > .region--main {
    @include lg() {
      grid-column: 4 / span 9;
      grid-row: 1 / span 2;
    }

    @include xl() {
      grid-column: 3 / span 8;
      grid-row: 1 / span 1;
    }
  }

  > .region--sidebar-left {
    @include lg() {
      grid-column: 1 / span 3;
    }

    @include xl() {
      grid-column: 1 / span 2;
    }
  }

  > .region--sidebar-right {
    @include lg() {
      grid-column: 1 / span 3;
    }

    @include xl() {
      grid-column: 11 / span 2;
    }
  }
}

.region {
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  padding-right: 20px;

  @include lg() {
    row-gap: 16px;
    padding-left: 0;
    padding-right: 0;
  }

  .region {
    padding-left: 0;
    padding-right: 0;
  }

  &--main {
    > .block {
      @include lg() {
        padding: 40px 48px 48px;
      }
    }
  }

  &--sidebar-left,
  &--sidebar-right {
    display: none;

    @include lg() {
      display: flex;
      flex-flow: column;
      row-gap: 16px;
    }
  }

  &--header-mobile {
    padding-top: 20px;

    @include lg() {
      display: none;
    }
  }
}

.footer {
  &--mobile {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.09), 0px 3px 2px 0px rgba(0, 0, 0, 0.05), 0px 5px 2px 0px rgba(0, 0, 0, 0.01), 0px 8px 2px 0px rgba(0, 0, 0, 0.00);

    @include lg() {
      display: none;
    }
  }
}
