@use "src/styles/general/mixins" as *;

.button,
.button.chakra-button {
  @include button();

  .chakra-button__icon {
    margin: 0;
  }
}

@each $color in $button-colors {
  @each $variant in $button-variants {
    .button--#{$variant}.button--#{$color} {
      @include button($color, $variant);
    }
  }
}
