/*.parse-html {
    color: white;
}*/

.parse-html p {
   margin: 0;
   font-size: 16px;
}
.parse-html *+p {
    margin-top: 8px;
}

.intro.parse-html {
    color: #FFDED1;
    text-align: center
}
.quote.parse-html {
    color: #0C1851;
}

.parse-html.hotspot__body,
.parse-html.tip__body {
    color: #0C1851;
}

.quote.parse-html p {
    font-size: 32px;
    line-height: 1.3;
    font-weight: 600;
    font-family: Lora;
}
.quote.parse-html h4 {
    font-size: 32px;
    line-height: 1.3;
    font-weight: 600;
}

.parse-html p.quotecaption {
    font-size: 18px;
    text-align: right;
}

.quote.parse-html p.quotecaption {
    color: #5A5F86;
}

.parse-html p.activity-title {
    color: #FFDED1;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 16px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.parse-html .button-link {
    background: #fff;
    border-radius: 9999px;
    color: #2a2a2a;
    display: inline-block;
    margin: 0.25rem;
    padding: 8px 24px;
}

.parse-html .button-link:hover {
    background: #f9f9f9;
}

@media screen and (max-width: 1000px) {
    .quote.parse-html p,
    .quote.parse-html h2,
    .quote.parse-html h3,
    .quote.parse-html h4,
    .quote.parse-html h5,
    .quote.parse-html h6 {
        margin: 0 0 24px 0;
        font-size: 24px;
        line-height: 32px;
        font-family: 'Lora', serif;
    }
}

.parse-html .highlighted {
    color: #FF9165;
}

.parse-html .muted {
    opacity: 0.5;
}

.parse-html .align-center img {
    margin-left: auto;
    margin-right: auto;
}

.parse-html table {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed
}

.parse-html thead th,
.parse-html thead td {
    background-color: #0C1851;
    font-family: Lora;
}

.parse-html thead tr:first-child th:first-child,
.parse-html thead tr:first-child td:first-child {
    border-top-left-radius: 8px;
}

.parse-html thead tr:first-child th:last-child,
.parse-html thead tr:first-child td:last-child {
    border-top-right-radius: 8px;
}

.parse-html tbody {
    color: #0C1851;
    font-size: 14px;
    line-height: 24px;
}

@media screen and (max-width: 768px) {
    .parse-html tbody {
        font-size: 12px;
        line-height: 16px;
    }
}

.parse-html tbody th,
.parse-html tbody td {
    background-color: #FFEEE7;
}

.parse-html tbody tr:last-child th:first-child,
.parse-html tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.parse-html tbody tr:last-child th:last-child,
.parse-html tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.parse-html tbody th,
.parse-html tbody td {
    border-top: 1px solid #D9D6E3;
    border-left: 1px solid #D9D6E3;
}

.parse-html tbody th:last-child,
.parse-html tbody td:last-child {
    border-right: 1px solid #D9D6E3;
}

.parse-html tbody tr:last-child th,
.parse-html tbody tr:last-child td {
    border-bottom: 1px solid #D9D6E3;
}

.parse-html tbody th:focus,
.parse-html tbody td:focus {
    background: #FFDED1;
}

.parse-html td.selected {
    color: white;
    background-color: #FF9165;
}

.parse-html td.no-value::after,
.parse-html th.no-value::after {
    content: "Add answer";
    opacity: 0.5;
}

.parse-html td.no-value:focus::after,
.parse-html th.no-value:focus::after {
    content: none;
}

.parse-html td,
.parse-html th {
    padding: 8px;
    overflow-wrap: anywhere;
}

.parse-html ul:not(:last-child) {
    margin-bottom: 8px;
}

.parse-html li {
    margin-left: 30px;
}

.menu-link.rotate {
    transform: rotate(180deg);
}

.notes-panel.rotate {
    transform: rotate(180deg);
}

