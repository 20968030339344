@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.modal {
  padding: 28px;
  display: flex;
  flex-flow: column;
  gap: 16px;
  margin: 20px;
  max-width: 500px;
  max-height: calc(100% - 40px);
  color: $color-navy;

  @include lg() {
    margin: 32px 20px;
    max-height: calc(100% - 64px);
  }

  a {
    color: $color-hyperlink;
    text-decoration: underline;
    font-weight: 600;
  }

  .modal__header {
    display: flex;
    gap: 16px;
    align-items: center;

    &--no-title {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  .modal__title {
    @include h4();
    flex: 1;

    input {
      @include h4();
      height: auto;
      padding: 8px;
    }
  }

  .modal__close {
    position: initial;
    width: 32px;
    height: 32px;
    min-width: auto;
    margin-left: auto;
    padding: 0;
    align-self: start;
    background: none;

    &:hover {
      background: none;
    }
  }

  .modal__body {
    padding: 2px;
  }

  .modal__footer {
    padding-top: 16px;
    justify-content: start;

    .button--help {
      margin-left: auto;
    }
  }

  &.modal--size-lg {
    max-width: 648px;
    padding: 36px 44px;
    gap: 16px;
  }

  &.modal--size-xl {
    max-width: 840px;
  }
}

.chakra-portal .modal {
  @extend .modal;
}
