@use "src/styles/general/colors" as *;
@use "src/styles/general/mixins" as *;

.badge {
  @include drop-shadow;

  align-items: center;
  background: $color-tangerine;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 8px;
  width: 40px;
}
