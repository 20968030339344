@use "src/styles/general/variables" as *;
@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.video-player {
  max-width: 100%;

  .shaka-video-container * {
    font-family: $body-font;
  }

  .shaka-scrim-container {
    background: none;
  }

  .shaka-bottom-controls {
    display: none;
  }

  .shaka-client-side-ad-container,
  .shaka-controls-container,
  .shaka-spinner-container {
    bottom: initial;
    height: 0;
    padding-bottom: 56.25%;
  }

  .shaka-spinner-container {
    .shaka-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__container {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }

  &__wrapper {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background: $color-black;
    }
  }

  &__error {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    color: $color-white;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    background: $color-black;
  }

  .player-controls {
    z-index: 2;
  }

  .shaka-text-container,
  .shaka-controls-container[shown=true]~.shaka-text-container {
    user-select: none;
    pointer-events: none;

    > * {
      position: initial !important;

      span {
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        background: rgba(42, 42, 42, 0.5) !important;
        text-align: left;
      }
    }
  }

  &--loading {
    .shaka-play-button-container {
      display: none;
    }

    .shaka-spinner-container.shaka-hidden {
      display: flex!important;
    }
  }

  &--default {
    width: 100%;
    flex-grow: 1;

    .player-controls {
      position: absolute;
      left: 8px;
      right: 8px;
      bottom: 8px;

      @include lg() {
        left: 24px;
        bottom: 24px;
        right: auto;
      }
    }

    .shaka-text-container,
    .shaka-controls-container[shown=true]~.shaka-text-container {
      position: absolute;
      left: 8px;
      right: 8px;
      bottom: 72px;
      top: auto;
      width: auto;
      margin-right: 112px;

      @include lg() {
        left: 24px;
        bottom: 88px;
        width: 416px;
      }
    }
  }

  &--small {
    width: 282px;
    max-width: 100%;
    align-self: center;

    .player-controls {
      width: 100%;
      min-width: initial;
    }
  }

  &--small,
  &--voiceover {
    .shaka-text-container,
    .shaka-controls-container[shown=true]~.shaka-text-container {
      position: initial;
      order: 1;
    }

    .player-controls {
      order: 2;
    }
  }

  &--voiceover {
    width: 282px;
    max-width: 100%;
    align-self: center;

    @include lg() {
      width: 416px;
      pointer-events: none;

      .video-player__container {
        pointer-events: none;

        > * {
          pointer-events: auto;
        }
      }

      .video-player__wrapper,
      .shaka-client-side-ad-container,
      .shaka-controls-container,
      .shaka-spinner-container {
        width: 160px;
        height: 90px;
        padding: 0;
      }
    }
  }

  &__container:-webkit-full-screen {
    position: relative;

    video {
      border-radius: 0;
    }

    .player-controls {
      position: absolute;
      left: 8px;
      bottom: 8px;
      right: 8px;
      width: auto;

      @include lg() {
        left: 24px;
        bottom: 24px;
        right: auto;
        width: 416px;
      }
    }

    .shaka-text-container,
    .shaka-controls-container[shown=true]~.shaka-text-container {
      position: absolute;
      bottom: 72px;
      left: 8px;
      right: 8px;
      width: auto;
      margin: 0;

      @include lg() {
        left: 24px;
        bottom: 88px;
        width: 416px;
      }
    }

    .video-player__wrapper,
    .shaka-client-side-ad-container,
    .shaka-controls-container,
    .shaka-spinner-container {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
}