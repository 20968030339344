.form {
  display: flex;
  flex-flow: column;
  row-gap: 16px;

  &__actions {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    justify-content: end;
  }
}
