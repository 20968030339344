@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.block {
  display: flex;
  flex-flow: column;
  row-gap: 24px;
  padding: 24px 0;

  > * {
    max-width: 100%;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
  }

  &__title {
    flex: 1;
    @include h4();
    color: $color-navy;
  }

  &__view-more {
    a {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
    }
  }

  @include lg() {
    padding: 32px 16px;
    background: $color-white;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.01), 0px 3px 2px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.09), 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }

  .block {
    row-gap: 16px;
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: initial;

    .block__title {
      @include lg() {
        @include h5();
      }
    }
  }

  .region--sidebar-left &,
  .region--sidebar-right & {
    padding: 24px;
  }
}