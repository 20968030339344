@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.segment-nav {
  &__item {
    position: relative;
    width: 164px;
    height: 92px;
    border-radius: 8px;
    @include box-shadow();

    @include lg() {
      width: 198px;
      height: 111px;
    }

    &--selected {
      outline: 2px solid $color-orange;

      .segment-nav__thumbnail {
        opacity: 1;
      }
    }

    &--locked {
      .segment-nav__thumbnail {
        opacity: 0.2;
      }
    }
  }

  &__thumbnail {
    border-radius: 8px;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.75;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include drop-shadow();
  }
}
