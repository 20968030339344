@use "src/styles/general/breakpoints" as *;

.background-sound {
  position: fixed;
  z-index: 10;
  right: 16px;
  bottom: 76px;

  &__mute {
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(42, 42, 42, 0.50);

    svg {
      width: 32px;
      height: 32px;
    }

    @include lg() {
      padding: 16px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  @include lg() {
    right: 24px;
    bottom: 24px;
  }
}
