@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.notes-block {
  &__list {
    display: flex;
    flex-flow: column;
    gap: 16px;

    @include md() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__actions {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 24px;
  }
}
