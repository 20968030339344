@use "src/styles/general/mixins" as *;

.block.profile-panel-block {
  padding: 16px;
}

.profile-panel {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 8px;

  .profile-panel__button {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 8px;
    padding: 8px;
    border-radius: 24px;
    flex-grow: 1;
    text-align: start;
    @include body-small();
    font-weight: 600;

    .chakra-button__icon {
      margin: 0;
    }

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.active,
    &[aria-expanded="true"] {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .profile-panel__avatar {
    width: 24px;
    height: 24px;
  }

  .profile-panel__notifications {
    display: block;
    padding: 8px;
  }
}
