@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.menu {
  display: flex;
  flex-flow: column;
  row-gap: 16px;

  &__item, {
    display: flex;
    flex-flow: column;

     > .menu__item-inner {
       display: flex;
       flex-flow: row nowrap;
       column-gap: 12px;
       padding: 8px 12px;
       border-radius: 8px;
       cursor: pointer;

       &:hover {
         background: rgba(0, 0, 0, 0.05);
       }
     }

    &--active {
      > .menu__item-inner {
        background: rgba(0, 0, 0, 0.10);
      }
    }

    &--expanded {
      > .menu__item-inner {
        background: rgba(0, 0, 0, 0.05);
      }

      .menu__item-chevron {
        transform: rotate(180deg);
      }
    }

    // Nested menu
    .menu {
      row-gap: 8px;
      margin-top: 16px;
    }

    .menu__item {
      row-gap: 8px;

      .menu__item-title {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }

      .menu__item-icon {
        align-self: start;
      }
    }
  }

  &__item-icon {
    flex: 0 0 16px;
    align-self: center;

    svg, img {
      width: 100%;
      height: auto;
    }
  }

  &__item-title {
    @include body();
    font-weight: 600;
    flex-grow: 1;
  }

  &__item-chevron {
    align-self: center;
    transition: transform 0.2s ease;
  }
}

div.dropdown-menu {
  display: flex;
  flex-flow: column;
  row-gap: 8px;
  padding: 8px 0;
  border: 1px solid $color-washed-blue;
  border-radius: 16px;
  background: $color-washed-orange;
  min-width: 224px;

  .dropdown-menu__item {
    @include body-large-bold();
    color: $color-navy;
    margin: 0 8px;
    border-radius: 8px;
    background-color: transparent;
    width: auto;

    > button {
      background: transparent;
      @include body-large-bold();
      color: inherit;
    }

    &:hover {
      background: $color-washed-blue;
    }
  }

  .chakra-menu__divider {
    margin: 0;
  }
}

div.dropdown-options {
  display: flex;
  flex-flow: column;
  row-gap: 16px;
  padding: 24px 0;
  border: 1px solid $color-washed-blue;
  border-radius: 16px;
  background: $color-washed-orange;
  min-width: initial;

  .dropdown-options__group,
  .chakra-menu__group {
    display: flex;
    flex-flow: column;
    row-gap: 16px;
  }

  .dropdown-options__item {
    @include body-large();
    display: flex;
    flex-flow: row nowrap;
    column-gap: 8px;
    align-items: center;
    margin: 0 24px;
    background: transparent;
    padding: 0;
    width: auto;

    .chakra-menu__icon-wrapper {
      margin: 0;
      display: none;
    }

    &:before {
      display: block;
      content: '';
      width: 16px;
      height: 16px;
      align-self: center;
      background: url("../../../img/icons/radio.svg") no-repeat;
      background-size: contain;
    }

    &[aria-checked="true"] {
      &:before {
        background-image: url("../../../img/icons/radio--checked.svg");
      }
    }
  }

  &--multiple {
    .dropdown-options__item {
      &:before {
        background-image: url("../../../img/icons/checkbox.svg");
      }

      &[aria-checked="true"] {
        &:before {
          background-image: url("../../../img/icons/checkbox--checked.svg");
        }
      }
    }
  }

  .chakra-menu__divider {
    margin: 0;
  }
}
