$color-navy: #0C1851;
$color-purple: #403171;
$color-orange: #FE5612;
$color-red: #E55E28;
$color-cream: #F8E9D5;

$color-tangerine: #FF9165;
$color-darkblue: #5A5F86;

$color-white: #FFFFFF;
$color-black: #000000;
$color-charcoal: #2A2A2A;
$color-darkgrey: #404040;
$color-light-grey: #F9F9F9;
$color-light-grey-2: #F0F0F0;
$color-light-grey-3: #E2E2E2;

$color-washed-orange: #FFEEE7;
$color-washed-tangerine: #FFDED1;
$color-washed-blue: #D9D6E3;

$color-hyperlink: #1746B9;
