@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.steps-cards {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 24px;

  &.steps-cards--complete {
    position: initial;
    height: auto;
    padding: 0;

    .steps-cards__track {
      justify-content: center;
      margin-top: 8px;
    }

    .steps-card {
      height: auto;
      flex-shrink: 1;
      padding-top: 32px;

      @include lg() {
        max-width: 136px;
        min-height: 280px;
      }
    }

    .steps-card__label {
      display: block;
    }
  }
}

.steps-cards__track {
  display: flex;
  flex-flow: column;
  gap: 24px;
  transition: all 0.4s;

  @include lg() {
    flex-flow: row;
    align-items: start;
  }
}

.steps-card {
  height: 160px;
  flex-shrink: 0;
  display: flex;
  flex-flow: column;
  row-gap: 16px;
  background: $color-cream;
  color: $color-black;
  padding: 12px 8px 8px;
  border-radius: 16px;
  text-align: center;
  position: relative;

  transition: all 0.4s;
  @include body;

  @include lg() {
    height: auto;
    max-width: 160px;
    min-height: 236px;
    transition-delay: 0s, 1s;
    transition-property: all, margin-left;
  }

  .parse-html {
    color: inherit;
    p {
      @include body;
    }
  }
}

.steps-card__body {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.steps-card__title {
  background: $color-navy;
  color: $color-white;
  padding: 4px 16px;
  border-radius: 8px;
  @include body-large-bold;
}

.steps-card__label {
  display: none;
  position: absolute;
  bottom: -28px;
  z-index: 1;
  padding: 4px 8px;
  min-width: 112px;
  background: $color-tangerine;
  color: $color-navy;
  border-radius: 8px;
  @include body-large-bold;

  @include lg() {
    top: -22px;
    bottom: auto;
    right: -50%;
  }
}