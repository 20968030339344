@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/colors" as *;
@use "src/styles/general/mixins" as *;

.app__loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page {
  &__heading {
    display: flex;
    flex-flow: row wrap;
    gap: 16px 8px;
    margin-bottom: 24px;

    @include lg() {
      flex-flow: row nowrap;
    }

    .page__icon {
      align-self: center;
    }

    .page__title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 8px;
      flex: 1;

      @include h4();
      color: $color-navy;
    }

    .page__title-suffix {
      margin-left: auto;
    }

    .page__actions {
      margin-left: auto;
      flex: 0 0 100%;

      @include lg() {
        flex: initial;
      }
    }

    &--main {
      .page__title {
        display: none;

        @include lg() {
          display: flex;
        }
      }
    }

    &--size-xl {
      .page__title {
        @include h2();
      }
    }

    &--size-lg {
      .page__title {
        @include h3();
      }
    }

    &--size-sm {
      .page__title {
        @include h5();
      }
    }
  }
}
