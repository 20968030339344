@use "src/styles/general/variables" as *;
@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;

@mixin body() {
  font-family: $body-font;
  font-size: 14px;
  line-height: 24px;
}

@mixin body-bold() {
  @include body;
  font-weight: 600;
}

@mixin body-large() {
  font-family: $body-font;
  font-size: 16px;
  line-height: 24px;
}

@mixin body-large-bold() {
  @include body-large;
  font-weight: 600;
}

@mixin body-small() {
  font-family: $body-font;
  font-size: 12px;
  line-height: 16px;
}

@mixin heading() {
  font-family: $heading-font;
  font-weight: 600;
  letter-spacing: normal;
}

@mixin h1() {
  @include heading();
  font-size: 40px;
  line-height: 48px;

  @include lg() {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
  }
}

@mixin h2() {
  @include heading();
  font-size: 32px;
  line-height: 40px;

  @include lg() {
    font-size: 40px;
    line-height: 48px;
  }
}

@mixin h3() {
  @include heading();
  font-size: 24px;
  line-height: 32px;

  @include lg() {
    font-size: 32px;
    line-height: 40px;
  }
}

@mixin h4() {
  @include heading();
  font-size: 18px;
  line-height: 24px;

  @include lg() {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin h5() {
  @include heading();
  font-size: 16px;
  line-height: 24px;

  @include lg() {
    font-size: 18px;
    line-height: 24px;
  }
}

@mixin h6() {
  @include heading();
  font-size: 16px;
  line-height: 24px;
}

@mixin tags() {
  font-family: $body-font;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

@mixin buttons() {
  font-family: $heading-font;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

@mixin h3h4 {
  @include heading();
  font-size: 24px;
  line-height: 32px;

  @include lg() {
    font-size: 24px;
    line-height: 32px;
  }
}

$button-colors: 'navy', 'orange', 'white', 'black';
$button-variants: 'solid', 'outline', 'link';
@mixin button($color: 'navy', $variant: 'solid') {
  $bgColor: map-get((
    'navy': $color-navy,
    'orange': $color-orange,
    'white': $color-white,
    'black': $color-charcoal,
  ), $color);
  $textColor: map-get((
    'navy': $color-white,
    'orange': $color-white,
    'white': $color-charcoal,
    'black': $color-white,
  ), $color);

  @include buttons;
  padding: 8px 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  border: 1px solid $bgColor;
  border-radius: 24px;
  cursor: pointer;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: .2s;

  svg {
    max-height: 20px;
    width: auto;

    path {
      transition-property: fill, stroke, opacity;
      transition-duration: .2s;
    }
  }

  @if ($variant == 'solid') {
    color: $textColor;
    background-color: $bgColor;

    path {
      fill: $textColor;
    }
  }
  @else if ($variant == 'outline') {
    background-color: transparent;
    color: $bgColor;

    path {
      fill: $bgColor;
    }
  }
  @else if ($variant == 'link') {
    border: none;
    border-radius: 0;
    background-color: transparent;
    color: $bgColor;
    padding-left: 0;
    padding-right: 0;

    path {
      fill: $bgColor;
    }
  }

  &:hover,
  &:active,
  &.js-active {
    @if ($variant == 'solid') {
      $bgColor: map-get((
              'navy': $color-darkblue,
              'orange': $color-tangerine,
              'white': $color-light-grey,
              'black': $color-darkgrey,
      ), $color);
      background-color: $bgColor;
      border-color: $bgColor;
    }
    @else if ($variant == 'outline') {
      color: $textColor;
      background-color: $bgColor;
      border-color: $bgColor;
    }

    @if ($variant == 'link') {
      background-color: transparent;

      path {
        fill: $bgColor;
      }

      &[disabled] {
        background-color: transparent;
      }
    }
    @else {
      path {
        fill: $textColor;
      }

      &[disabled] {
        background-color: $bgColor;
      }
    }
  }
}

@mixin drop-shadow() {
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.10))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10))
    drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 8px 2px rgba(0, 0, 0, 0.00));
}

@mixin box-shadow() {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10),
    0px 0px 1px 0px rgba(0, 0, 0, 0.10),
    0px 1px 1px 0px rgba(0, 0, 0, 0.09),
    0px 3px 2px 0px rgba(0, 0, 0, 0.05),
    0px 5px 2px 0px rgba(0, 0, 0, 0.01),
    0px 8px 2px 0px rgba(0, 0, 0, 0.00);
}
