@use "src/styles/general/animation" as *;

@keyframes hearts-click {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.6);
  }
  28% {
    transform: scale(1.25);
  }
  84% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes hearts {
  0% {
    bottom: 0;
  }
  36% {
    opacity: 0;
    transform: scale(0);
  }
  56% {
    opacity: 1;
    bottom: 50%;
  }
  84% {
    transform: scale(1);
    bottom: 100%;
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.like {
  align-self: center;

  > button {
    display: block;
  }

  &--processing {
    .like__button {
      cursor: initial;

      svg {
        @include animation-loading-pulse();
      }
    }
  }

  &.like--clicked {
    .like__button {
      svg {
        @include animation-icon-click();
      }
    }
  }

  &.like--variant-heart {
    &.like--clicked.like--liked {
      .like__button {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          width: 28px;
          height: 19px;
          opacity: 0;
          left: 50%;
          bottom: 100%;
          margin-left: -14px;
          background: url("../../../img/hearts.svg") no-repeat;
          animation: hearts 2.1s ease-in-out;
        }

        svg {
          animation: hearts-click 2.1s ease-in-out;
        }
      }
    }
  }
}
