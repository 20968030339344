@use "src/styles/general/mixins" as *;

.add-note-form {
  input,
  textarea {
    padding: 0;
    border-radius: 0;
    border: 0;
    outline: none;

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }
  }

  input[name="title"] {
    @include h6();
  }
}