@use "src/styles/general/colors" as *;

.progressbar {
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.08);
  box-shadow: inset -1px 1px 1px rgba(0, 0, 0, 0.25);

  [role="progressbar"] {
    background-color: $color-orange;
    border-radius: 30px;
  }
}